<template>
  <div class="list-info">
    <div class="cont">
      <van-list v-model="loading" :finished="finished" @load="getDataList" v-if="dataList.length > 0" offset="10">
        <div class="list-item video-item" v-for="item in dataList" :key="item.name" @click="changeVideo(item.status, item.url, item.name)"
             :style="{border:item.name==currentVideoName?'1px solid blue':'0'}">
          <div class="title">
            <div class="title-left">
              {{item.name}}
            </div>
            <div class="title-right" :style="{color:item.status=='online'?'green':'red'}">
              {{item.status == 'online' ? '在线' : '离线'}}
            </div>
          </div>
        </div>
      </van-list>
      <van-image v-else :src="require('@/assets/img/empty.png')" style="margin-left:10%;width:80%;"></van-image>
      <div class="video-info">
        <video class="video" controls autoplay muted width="100%" height="100%" ref="video"/>
      </div>
    </div>
  </div>
</template>

<script>
import Hls from 'hls.js'
export default {
  data () {
    return {
      hls: null,
      dataList: [],
      currentVideoName: '',
      loading: false,
      finished: false
    }
  },
  mounted () {
    this.hls = new Hls()
    this.hls.attachMedia(this.$refs.video)

    this.hls.on(Hls.Events.MANIFEST_PARSED, function () {
      this.$refs.video.pause()
    })
    this.getDataList()
  },
  beforeDestroy() {
    this.hls.stopLoad()
    this.hls.destroy()
  },
  methods: {
    getDataList () {
      this.$http({
        url: this.$http.adornUrl('/wxapp/statistics/monitoringVideo'),
        method: "get"
      }).then(({ data }) => {
        if (data.videos) {
          this.dataList = data.videos
          // 初始化默认播放第一个正常的视频
          for(let i = 0; i < data.videos.length; i++) {
            if (data.videos[i].url) {
              this.playVideo(data.videos[i].url, data.videos[i].name)
              break
            }
          }
        } else {
          this.$toast.fail('视频获取失败!')
          setTimeout(() => {
            this.$router.go(-1)
          }, 1500)
        }
        this.loading = false
        this.finished = true
      })
    },
    // 切换视频
    changeVideo(status, url, name) {
      if (status === 'offline') {
        this.$toast.fail("无法播放离线视频")
        return
      }
      if (this.currentVideoName === name) {
        return
      }
      this.playVideo(url, name)
    },
    // 播放视频
    playVideo(url, name) {
      this.$refs.video.pause()
      this.hls.loadSource(url)
      this.currentVideoName = name
      this.$nextTick(() => {
        this.$refs.video.play()
      })
    }
    // videoPlay () {
    //   let myVideo = document.getElementsByClassName('video')[0]
    //   var docHtml  = document.documentElement
    //   var docBody  = document.body
    //   var videobox  = document.getElementById('videobox')
    //   var cssText = 'width:100%;height:100%;overflow:hidden;'
    //   docHtml.style.cssText = cssText
    //   docBody.style.cssText = cssText
    //   videobox.style.cssText = cssText+';'+'margin:0px;padding:0px;'
    //   document.IsFullScreen = true
    //   // this.launchFullscreen(myVideo)
    // },
    // launchFullscreen (element) {
    //   debugger
    //   //此方法不可以在異步任務中執行，否則火狐無法全屏
    //   if (element.requestFullscreen) {
    //     element.requestFullscreen()
    //   } else if (element.mozRequestFullScreen) {
    //     element.mozRequestFullScreen()
    //   } else if (element.msRequestFullscreen) {
    //     element.msRequestFullscreen()
    //   } else if (element.oRequestFullscreen) {
    //     element.oRequestFullscreen()
    //   }
    //   else if (element.webkitRequestFullscreen) {
    //     element.webkitRequestFullScreen()
    //   } else {

    //   }
    // },
    // exitFullscreen () {
    //   if (document.exitFullscreen) {
    //     document.exitFullscreen()
    //   } else if (document.msExitFullscreen) {
    //     document.msExitFullscreen()
    //   } else if (document.mozCancelFullScreen) {
    //     document.mozCancelFullScreen()
    //   } else if (document.oRequestFullscreen) {
    //     document.oCancelFullScreen()
    //   } else if (document.webkitExitFullscreen) {
    //     document.webkitExitFullscreen()
    //   } else {
    //     var docHtml  = document.documentElement
    //     var docBody  = document.body
    //     var videobox  = document.getElementById('videobox')
    //     docHtml.style.cssText = ""
    //     docBody.style.cssText = ""
    //     videobox.style.cssText = ""
    //     document.IsFullScreen = false
    //   }
    // },
  }
}
</script>
<style scoped>
.video-info {
  height: 87vh;
  background-color: #000;
}
.video-item{
  margin-bottom: 0.3rem !important;
}
</style>
